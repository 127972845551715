import { DIFFICULT_TYPE } from './constants';
import { GridType, SegmentTransitionType, SmartTetriminoMode } from './enumeration';
import { BlockerSpawn, ChargeBarConfigs, TargetsAndRewards } from './models';

export const OBJECTIVE_INIT = {
  objectiveId: '0',
  number: 0,
};

export const GENERAL_INIT = {
  // id: '1',
  embeddedId: '',
  gridType: GridType.Segment,
  segmentTransitionType: SegmentTransitionType.Vertical,
  numberOfStages: 1,
  numberOfRows: 14,
  difficult: DIFFICULT_TYPE[0].value,
  isForceNextLevel: false,
  moves: 20,
  ftue: false,
  winRate: 100,
  moveLeft: 1,
  scrollStartLine: 0,
  scrollLinesAmount: 0,
  startingLine: 0,
  SmartTetriminoMode: SmartTetriminoMode.NONE,
  StartSmartTetriminosAfterMoves: 0,
  WinStreakAfterLevel: 0,
  LossStreakAfterLevel: 0,
  AverageWinAfterLevel: 10,
  IsEnableNextNext: false,
  ListPowerUp: [],
  MaxChargeValue: 0,
  maxRow: 14,
  maxColumn: 10,
  CoinTokenValue: 0,
};

export const BLOCKER_INIT: BlockerSpawn = {
  freeSpawn: [
    {
      blocker: '0',
      maxNumber: 0,
      totalNumber: 0,
      maxNumberInSingleSpawn: 0,
      spawnInterval: 0,
      spawnStart: 0,
    },
  ],
  embeddedId: '',
  baseRate: 0,
  maxRate: 0,
  numberOfItem: 0,
  itemWithPositions: {
    items: {},
  },
  items: [
    {
      subItems: [
        {
          typeName: '0',
          weight: 0,
          quantity: 0,
        },
      ],
      weight: 0,
    },
  ],
  commitmentAtRoll: -1,
  increaseRate: [
    {
      increaseRate: 0,
      minRoll: 0,
      maxRoll: 0,
    },
  ],
};

export const TARGET_AND_REWARDS_INIT: TargetsAndRewards = {
  type: undefined,
  rewards: [],
  numberOfLine: 40,
  score: 0,
  blockerStatistic: [],
  clearObjects: [],
};

export const CHARGE_BAR_CONFIG_INIT: ChargeBarConfigs = {
  ChargeBarLevelsList: [],
  EnableChargeBarSystem: false,
  ChargeBarSlots: [],
};
