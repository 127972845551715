import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import Bluebird from 'bluebird';
import _ from 'lodash';

import { useGlobalChargeBarCollectionContext } from '@providers/charge-bar-collection';
import {
  ObjectCollection,
  SegmentCollection,
  useGlobalObjectCollectionContext,
} from '@providers/object-collection';

import { DEFAULT_OVER_SIZE_TILE, DEFAULT_TILE } from './components/level-data/layout/tile-select';
import {
  BlockType,
  GridType,
  RotationDegree,
  Square,
  TargetType,
  TileBlock,
} from './utils/enumeration';
import {
  BLOCKER_INIT,
  CHARGE_BAR_CONFIG_INIT,
  GENERAL_INIT,
  TARGET_AND_REWARDS_INIT,
} from './utils/initData';
import {
  Blocker,
  BlockerSpawn,
  CellData,
  LayoutStages,
  LayoutState,
  MapLevelDataContext,
  TilesState,
} from './utils/models';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageExists = require('image-exists');

const layoutStages: LayoutStages = [];
const [DEFAULT_WIDTH, DEFAULT_HEIGHT] = [10, 20];

const useLevelDataContextValue = () => {
  // form
  const [formGeneral] = Form.useForm();
  const [formTetromino] = Form.useForm();
  const [formChargeBar] = Form.useForm();
  const [formBlockerSpawn] = Form.useForm();
  const [formTargetAndRewards] = Form.useForm();
  // const [formAiScore] = Form.useForm();

  const [maxWidth, setMaxWidth] = useState(DEFAULT_WIDTH);
  const [maxHeight, setMaxHeight] = useState(DEFAULT_HEIGHT);
  const [startLine, setStartLine] = useState(0);
  const [scrollLinesAmount, setScrollLinesAmount] = useState(0);
  const [maxLayer] = useState(4);
  const [stageSelected, _setStageSelected] = useState(1);
  const [layerSelected, _setLayerSelected] = useState(1);
  const [stages, _setStages] = useState(3);
  const [layers, _setLayer] = useState(1);
  const [stageCopy, setStageCopy] = useState('');
  const [selected, setSelected] = useState<string>('E');

  const [blockerStatistic, setBlockerStatistic] = useState<Record<string, number>>({});

  // embedded position
  const [embeddedPositionI, setEmbeddedPositionI] = useState<number[]>([]);
  const [embeddedPositionL, setEmbeddedPositionL] = useState<number[]>([]);
  const [embeddedPositionJ, setEmbeddedPositionJ] = useState<number[]>([]);
  const [embeddedPositionO, setEmbeddedPositionO] = useState<number[]>([]);
  const [embeddedPositionS, setEmbeddedPositionS] = useState<number[]>([]);
  const [embeddedPositionZ, setEmbeddedPositionZ] = useState<number[]>([]);
  const [embeddedPositionT, setEmbeddedPositionT] = useState<number[]>([]);

  // init
  const getInitialTiles = () => {
    const data: LayoutState = {};

    for (let stageId = 1; stageId <= 1; stageId += 1) {
      for (let layerId = 1; layerId <= 4; layerId += 1) {
        data[layerId] = {};
        for (let i = 0; i < maxWidth; i += 1) {
          for (let j = 0; j < maxHeight; j += 1) {
            data[layerId][`${i}_${j}`] = {
              tileId: DEFAULT_TILE.id,
              image: DEFAULT_TILE.name,
              rootX: i,
              rootY: j,
              isHidden: false,
              isBlock: false,
            };
          }
        }
      }
      layoutStages[stageId] = data;
    }

    return layoutStages[1];
  };

  const getBoardMaxtrixsInit = (addToStage?: number) => {
    const data: Array<string> = [];
    const _stages = addToStage ?? stages;

    for (let stageId = addToStage ?? 1; stageId <= _stages; stageId += 1) {
      let boardMatrixByStageId = '';
      for (let i = 0; i < maxWidth; i += 1) {
        for (let j = 0; j < maxHeight; j += 1) {
          if (!boardMatrixByStageId) boardMatrixByStageId += '0';
          else boardMatrixByStageId += ',0';
        }
      }
      data[stageId] = boardMatrixByStageId;
    }

    return data;
  };

  const { tileMap, tileBlockers, tetrominos } = useGlobalObjectCollectionContext();

  // const { chargebarTemplate, fetch } = useGlobalChargeBarCollectionContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tetrominoInit, setTetrominoInit] = useState<Record<string, any>>();
  useEffect(() => {
    const initData = tetrominos.reduce<Record<string, any>>((acc, tetromino) => {
      const key = tetromino.shortName as string;
      acc[key] = Number(tetromino.defaultValue);

      return acc;
    }, {});

    setTetrominoInit(initData);
  }, [tetrominos]);

  const getInitialLevel = () => {
    const data: MapLevelDataContext = {
      id: '',
      general: _.cloneDeep(GENERAL_INIT),
      boardMatrixs: getBoardMaxtrixsInit(),
      tetromino: _.cloneDeep(tetrominoInit as Record<string, any>),
      blocker: _.cloneDeep(BLOCKER_INIT),
      enemies: [],
      targetsAndRewards: _.cloneDeep(TARGET_AND_REWARDS_INIT),
      chargeBarConfigs: _.cloneDeep(CHARGE_BAR_CONFIG_INIT),
    };

    return data;
  };

  const [levelData, setLevelData] = useState(getInitialLevel());

  useEffect(() => {
    if (levelData.general?.maxColumn) {
      setMaxWidth(levelData.general.maxColumn);
    }
  }, [levelData.general?.maxColumn]);

  const [changedRow, setChangedRow] = useState(maxHeight - levelData.general?.maxRow);
  const [curLevel, setCurLevel] = useState(1);
  const [formObjective] = Form.useForm();
  const [formEnemy] = Form.useForm();
  const [curColumn, setCurColumn] = useState(0);
  const [tileGlobal, setTileGlobal] = useState<string>(DEFAULT_TILE.id);
  const [tileBlock, setTileBlock] = useState<string>(''); // rotate block

  const [tileSegment, setTileSegment] = useState<SegmentCollection>();
  const [tileMeta, setTileMeta] = useState<any>();

  // general form
  const [gridType, setGridType] = useState(GridType.Segment);

  const [tileMapFilter, setTileMapFilter] = useState<Record<string, ObjectCollection>>({});
  const setLevelSelect = (dataLevel: any) => {
    if (dataLevel === undefined) {
      return;
    }
    setLevelData({ ...dataLevel });
  };

  const syncBlockerTile = async (index: any) => {
    const { blockerStatistic: blockerStatisticVar } = levelData.targetsAndRewards;
    const bockerStasInfo = blockerStatisticVar[index];

    let checkingBlockerExist = false;
    await Bluebird.map(levelData.targetsAndRewards.clearObjects ?? [], (item: any) => {
      if (item && item.name === bockerStasInfo.blockerName) {
        item.number = bockerStasInfo?.total ?? 0;
        checkingBlockerExist = true;
      }
    });

    if (!checkingBlockerExist) {
      levelData.targetsAndRewards.clearObjects.push({
        objectiveId: bockerStasInfo.blockerId,
        name: bockerStasInfo.blockerName,
        number: bockerStasInfo?.total ?? 0,
      });
    }

    setLevelData({ ...levelData });
  };

  // context for grid:
  const [selectedTile, setSelectedTile] = useState<ObjectCollection | undefined>(DEFAULT_TILE);
  const [tiles, setTiles] = useState(getInitialTiles());

  const [isMouseDown, setMouseDown] = useState(false);
  const [tileWidth, tileHeight] = selectedTile?.size
    ? selectedTile?.size?.split(':')?.map((item) => parseInt(item, 10))
    : [0, 0];

  const subBlock = (color: string, x: number, y: number) => {
    return {
      tileId: DEFAULT_TILE.id,
      image: color,
      rootX: x,
      rootY: y,
      isHidden: false,
      isSubBlock: `${x}.${y}`,
      isBlock: true,
    };
  };

  const mainBlock = (id: string, color: string, x: number, y: number) => {
    return {
      tileId: id,
      image: color,
      rootX: x,
      rootY: y,
      isHidden: false,
      isBlock: true,
      isCenter: true,
    };
  };

  const conditionTile = (
    condition: boolean,
    x: number,
    y: number,
    keyTile: string,
    currentKey: string,
    id: string,
    color: string,
  ) => {
    if (keyTile === currentKey) {
      return mainBlock(id, color, x, y);
    }
    if (condition) {
      return subBlock(color, x, y);
    }
  };

  /**
   * render tile block to grid by type
   * @param tileBlockRotate // check rotate statee
   * @param x
   * @param y
   * @param keyTile
   * @param currentKey
   * @param tileMain
   * @param conditionNormal
   * @param conditionDeg90
   * @param conditionDeg180
   * @param conditionDeg270
   * @param color
   * @returns
   */
  const mappingTileBlock = (
    tileBlockRotate: string[],
    x: number,
    y: number,
    keyTile: string,
    currentKey: string,
    id: string,
    conditionNormal: boolean,
    conditionDeg90: boolean,
    conditionDeg180: boolean,
    conditionDeg270: boolean,
    color: string,
  ) => {
    if (tileBlockRotate.length === 1)
      return conditionTile(conditionNormal, x, y, keyTile, currentKey, id, color);

    switch (Number(tileBlockRotate[1])) {
      case RotationDegree.Deg90:
        return conditionTile(conditionDeg90, x, y, keyTile, currentKey, id, color);
      case RotationDegree.Deg180:
        return conditionTile(conditionDeg180, x, y, keyTile, currentKey, id, color);
      case RotationDegree.Deg270:
        return conditionTile(conditionDeg270, x, y, keyTile, currentKey, id, color);
      default:
        break;
    }
  };

  const setTileData = (
    x: number,
    y: number,
    tile: ObjectCollection,
    curLayer: number,
    durability?: number,
    spawnRate?: number,
    ziplineIndex?: number,
  ) => {
    if (!tile) return;

    setTiles((current) => {
      const currentKey = `${x}_${y}`;
      const currentLayerState = current[curLayer];

      const layerState = Object.keys(currentLayerState).reduce<TilesState>((all, keyTile) => {
        let currentTile = currentLayerState[keyTile] ?? {
          tileId: DEFAULT_TILE.id,
          image: DEFAULT_TILE.name,
          rootX: x,
          rootY: y,
          isHidden: false,
        };

        if (x + tileWidth > maxWidth || y - tileHeight + 1 < 0) {
          return currentLayerState;
        }

        const idOriginal = _.cloneDeep(tile.id);
        const [tileX, tileY] = keyTile.split('_').map((item) => parseInt(item, 10));
        const tileBlockRotate = tile.id.split('.');
        switch (Number(tileBlockRotate[0])) {
          case TileBlock.TileI:
            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idOriginal,
                (tileY === y && (tileX === x - 1 || tileX === x - 2 || tileX === x + 1)) ||
                  (tileY === y && tileX === x),
                (tileX === x && (tileY === y - 2 || tileY === y - 1 || tileY === y + 1)) ||
                  (tileY === y && tileX === x),
                (tileY === y && (tileX === x - 1 || tileX === x + 2 || tileX === x + 1)) ||
                  (tileY === y && tileX === x),
                (tileX === x && (tileY === y + 2 || tileY === y - 1 || tileY === y + 1)) ||
                  (tileY === y && tileX === x),
                Square.LightBlue,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileO:
            if (
              (tileY === y && tileX === x + 1) ||
              (tileY === y + 1 && (tileX === x || tileX === x + 1))
            ) {
              currentTile = subBlock(Square.Yellow, x, y);
            } else if (keyTile === currentKey) {
              currentTile = mainBlock(idOriginal, Square.Yellow, x, y);
            }
            break;
          case TileBlock.TileT:
            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idOriginal,
                (tileY === y && (tileX === x + 1 || tileX === x - 1)) ||
                  (tileY === y + 1 && tileX === x),
                (tileX === x && (tileY === y - 1 || tileY === y + 1)) ||
                  (tileX === x + 1 && tileY === y),
                (tileY === y && (tileX === x - 1 || tileX === x + 1)) ||
                  (tileY === y - 1 && tileX === x),
                (tileX === x - 1 && tileY === y) ||
                  (tileX === x && (tileY === y - 1 || tileY === y + 1)),
                Square.Purple,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileL:
            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idOriginal,
                (tileY === y && (tileX === x + 1 || tileX === x - 1)) ||
                  (tileY === y + 1 && tileX === x + 1),
                (tileX === x && (tileY === y - 1 || tileY === y + 1)) ||
                  (tileX === x + 1 && tileY === y - 1),
                (tileY === y && (tileX === x - 1 || tileX === x + 1)) ||
                  (tileY === y - 1 && tileX === x - 1),
                (tileX === x - 1 && tileY === y + 1) ||
                  (tileX === x && (tileY === y - 1 || tileY === y + 1)),
                Square.Orange,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileJ:
            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idOriginal,
                (tileY === y && (tileX === x + 1 || tileX === x - 1)) ||
                  (tileY === y + 1 && tileX === x - 1),
                (tileX === x && (tileY === y - 1 || tileY === y + 1)) ||
                  (tileX === x + 1 && tileY === y + 1),
                (tileY === y && (tileX === x - 1 || tileX === x + 1)) ||
                  (tileY === y - 1 && tileX === x + 1),
                (tileX === x - 1 && tileY === y - 1) ||
                  (tileX === x && (tileY === y - 1 || tileY === y + 1)),
                Square.Blue,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileS:
            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idOriginal,
                (tileY === y && tileX === x - 1) ||
                  (tileY === y + 1 && (tileX === x || tileX === x + 1)),
                (tileX === x && tileY === y + 1) ||
                  (tileX === x + 1 && (tileY === y - 1 || tileY === y)),
                (tileY === y && tileX === x + 1) ||
                  (tileY === y - 1 && (tileX === x - 1 || tileX === x)),
                (tileX === x - 1 && (tileY === y + 1 || tileY === y)) ||
                  (tileX === x && tileY === y - 1),
                Square.Green,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileZ:
            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idOriginal,
                (tileY === y && tileX === x + 1) ||
                  (tileY === y + 1 && (tileX === x || tileX === x - 1)),
                (tileX === x && tileY === y - 1) ||
                  (tileX === x + 1 && (tileY === y + 1 || tileY === y)),
                (tileY === y && tileX === x - 1) ||
                  (tileY === y - 1 && (tileX === x + 1 || tileX === x)),
                (tileX === x - 1 && (tileY === y || tileY === y - 1)) ||
                  (tileX === x && tileY === y + 1),
                Square.Red,
              ) ?? currentLayerState[keyTile];
            break;
          default:
            if (tile.size === '2:2') {
              if (
                (tileY === y && tileX === x + 1) ||
                (tileY === y + 1 && (tileX === x || tileX === x + 1))
              ) {
                currentTile = {
                  tileId: DEFAULT_OVER_SIZE_TILE.id,
                  image: DEFAULT_OVER_SIZE_TILE.id,
                  rootX: x,
                  rootY: y,
                  isHidden: true,
                };
              } else if (keyTile === currentKey) {
                // if (x + tileWidth > maxWidth || y - tileHeight + 1 < 0) {
                //   return currentLayerState;
                // }
                currentTile = {
                  tileId: tile.id,
                  image: tile.name,
                  rootX: x,
                  rootY: y,
                  isHidden: false,
                };
              }
            } else if (keyTile === currentKey) {
              if (currentTile.rootX !== x || currentTile.rootY !== y) {
                currentTile.tileId = tile.id;
              } else {
                currentTile = {
                  tileId: tile.id,
                  image: tile.name,
                  rootX: x,
                  rootY: y,
                  isHidden: false,
                };

                if (tile.haveSegment) {
                  currentTile.durability = durability;
                  currentTile.spawnRate = spawnRate;
                  currentTile.image =
                    tileMap[tile.id].segments?.find((item) => item.durability === durability)
                      ?.imageName ?? currentTile.image;
                } else if (tile.haveMetaData) {
                  currentTile.durability = durability;

                  currentTile.image = tileMap[tile.id].tileMetaData?.[ziplineIndex as number]
                    .imageName as string;

                  currentTile.index = ziplineIndex;
                }
              }
            }
            break;
        }

        all[keyTile] = currentTile;
        return all;
      }, {});

      return {
        ...current,
        [curLayer]: layerState,
      };
    });
  };

  const validateSetTile = (
    tileBlockRotate: string[],
    condition: boolean,
    conditionDeg90: boolean,
    conditionDeg180: boolean,
    conditionDeg270: boolean,
  ) => {
    if (tileBlockRotate.length > 1) {
      if (Number(tileBlockRotate[1]) === RotationDegree.Deg90 && conditionDeg90) return true;
      if (Number(tileBlockRotate[1]) === RotationDegree.Deg180 && conditionDeg180) return true;
      if (Number(tileBlockRotate[1]) === RotationDegree.Deg270 && conditionDeg270) return true;
    } else if (condition) return true;
    else return false;
  };

  const getDurAndSpawnInfo = (tileInfo: string) => {
    // if coin multi hits split durability and spawn rate
    const openBracketIndex = tileInfo.indexOf('(');
    const pipeIndex = tileInfo.indexOf('|');
    const firstNumber = tileInfo.substring(openBracketIndex + 1, pipeIndex);
    const secondNumber = parseInt(tileInfo.substring(pipeIndex + 1, tileInfo.length - 1), 10);
    const id = tileInfo.substring(0, openBracketIndex);

    let durability;
    if (firstNumber && firstNumber.includes('_'))
      durability = Number(firstNumber.replace('_', '-'));
    else durability = Number(firstNumber);

    const spawnRate = secondNumber;

    return { durability, spawnRate, id };
  };

  const initBlockerStatistic = (
    tileId: string,
    tileName: string,
    blockerStatisticParams: Blocker[],
    originTileId?: string,
  ) => {
    const indexToUpdate = _.findIndex(blockerStatisticParams, {
      blockerId: tileId,
    });

    const factor = originTileId ? tileMap[originTileId]?.numberOfTileCreate : 0;
    const addingNumber = factor && factor > 1 ? Number(factor) : 1;
    if (indexToUpdate !== -1) {
      blockerStatisticParams[indexToUpdate].total += addingNumber;
    } else {
      blockerStatisticParams.push({
        blockerId: tileId,
        blockerName: tileName,
        total: addingNumber,
      });
    }

    return blockerStatisticParams;
  };

  const checkTileIsBlocker = async (boardData: any) => {
    if (!boardData) return;
    let localBlockerStatistic: Blocker[] = [];
    let stageItems = [1];
    if (stages > 1) {
      stageItems = Array.from({ length: stages }, (__, i) => i + 1);
    }

    // count blocker initialization
    const widthItems = [...Array(maxWidth).keys()];
    const heightItems = [...Array(maxHeight).keys()];

    await Bluebird.map(stageItems, async (stageItem: number) => {
      const mapLayerString2 = boardData[stageItem];

      const arrObject2 = mapLayerString2?.split(',');

      if (!arrObject2) return;
      // levelData.targetsAndRewards.blockerStatistic = {};

      await Bluebird.map(widthItems, (i: number) => {
        Bluebird.map(heightItems, (j: number) => {
          let objectData = DEFAULT_TILE;
          const arrObjectOnCell = arrObject2[i * maxHeight + j]?.trim().split('-');
          for (let k = 0; k < arrObjectOnCell?.length; k += 1) {
            // kiểm tra các object nằm trong tile có thuộc loại chứa durability hay không?
            if (arrObjectOnCell[k].includes('|')) {
              // lấy durability and spawn cho tile nằm trên board
              const tileInfo = getDurAndSpawnInfo(arrObjectOnCell[k]);

              // if coin multi hits split durability and spawn rate
              objectData = tileMap[tileInfo.id];
            } else {
              objectData = tileMap[arrObjectOnCell[k]];
            }

            // if (selected !== 'E' && tileBlockers[selected]) {
            //   objectData = tileMap[selected];
            // }

            // thống kê số lương blockers
            if (objectData?.name && tileBlockers[objectData.id]) {
              localBlockerStatistic = initBlockerStatistic(
                objectData.objectiveType ?? objectData.id,
                objectData.objectiveType ? tileMap[objectData.objectiveType].name : objectData.name,
                localBlockerStatistic,
                objectData.id,
              );
            }
          }
        });
      });
    });

    setLevelData({
      ...levelData,
      targetsAndRewards: {
        ...levelData.targetsAndRewards,
        blockerStatistic: localBlockerStatistic,
      },
    });
  };

  const setTile = (x: number, y: number, tile?: ObjectCollection, layerInit?: number) => {
    if (!selectedTile) return;

    setTiles((current) => {
      const currentKey = `${x}_${y}`;
      const currentLayerState = current[layerInit ?? layers];

      const defaultTile = {
        tileId: DEFAULT_TILE.id,
        image: DEFAULT_TILE.name,
        rootX: x,
        rootY: y,
        isHidden: false,
      };

      const tileMain = tile ?? selectedTile;

      const layerState = Object.keys(currentLayerState).reduce<TilesState>((all, keyTile) => {
        let currentTile: CellData = currentLayerState[keyTile] ?? defaultTile;
        // const { rootX } = currentTile;
        // const { rootY } = currentTile;
        const [tileX, tileY] = keyTile.split('_').map((item) => parseInt(item, 10));
        const tileBlockRotate = tileBlock.split('.');
        let idBlockSave = tileMain.id;
        if (tileBlock) {
          idBlockSave = tileBlock;
        }

        switch (Number(tileMain.id)) {
          case TileBlock.TileI:
            if (
              validateSetTile(
                tileBlockRotate,
                x < 2 || x === maxWidth - 1,
                y < 2 || y === maxHeight - 1,
                x < 1 || x > maxWidth - 2,
                y < 1 || y > maxHeight - 2,
              )
            )
              break;

            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idBlockSave,
                (tileY === y && (tileX === x - 1 || tileX === x - 2 || tileX === x + 1)) ||
                  (tileY === y && tileX === x),
                (tileX === x && (tileY === y - 2 || tileY === y - 1 || tileY === y + 1)) ||
                  (tileY === y && tileX === x),
                (tileY === y && (tileX === x - 1 || tileX === x + 2 || tileX === x + 1)) ||
                  (tileY === y && tileX === x),
                (tileX === x && (tileY === y + 2 || tileY === y - 1 || tileY === y + 1)) ||
                  (tileY === y && tileX === x),
                Square.LightBlue,
              ) ?? currentLayerState[keyTile];
            break;

          case TileBlock.TileO:
            // skip
            if (x === maxWidth - 1 || y >= maxHeight - 1) break;

            if (
              (tileY === y && tileX === x + 1) ||
              (tileY === y + 1 && (tileX === x || tileX === x + 1))
            ) {
              currentTile = subBlock(Square.Yellow, x, y);
            } else if (keyTile === currentKey) {
              currentTile = mainBlock(tileMain.id, Square.Yellow, x, y);
            }
            break;
          case TileBlock.TileT:
            if (
              validateSetTile(
                tileBlockRotate,
                x < 1 || x === maxWidth - 1 || y === maxHeight - 1,
                x === maxWidth - 1 || y === maxHeight - 1 || y === 0,
                x === maxWidth - 1 || y === 0 || x === 0,
                x === 0 || y === maxHeight - 1 || y === 0,
              )
            )
              break;

            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idBlockSave,
                (tileY === y && (tileX === x + 1 || tileX === x - 1)) ||
                  (tileY === y + 1 && tileX === x),
                (tileX === x && (tileY === y - 1 || tileY === y + 1)) ||
                  (tileX === x + 1 && tileY === y),
                (tileY === y && (tileX === x - 1 || tileX === x + 1)) ||
                  (tileY === y - 1 && tileX === x),
                (tileX === x - 1 && tileY === y) ||
                  (tileX === x && (tileY === y - 1 || tileY === y + 1)),
                Square.Purple,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileL:
            if (
              validateSetTile(
                tileBlockRotate,
                x < 1 || x === maxWidth - 1 || y === maxHeight - 1,
                x === maxWidth - 1 || y === maxHeight - 1 || y === 0,
                x === maxWidth - 1 || y === 0 || x === 0,
                x === 0 || y === maxHeight - 1 || y === 0,
              )
            )
              break;

            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idBlockSave,
                (tileY === y && (tileX === x + 1 || tileX === x - 1)) ||
                  (tileY === y + 1 && tileX === x + 1),
                (tileX === x && (tileY === y - 1 || tileY === y + 1)) ||
                  (tileX === x + 1 && tileY === y - 1),
                (tileY === y && (tileX === x - 1 || tileX === x + 1)) ||
                  (tileY === y - 1 && tileX === x - 1),
                (tileX === x - 1 && tileY === y + 1) ||
                  (tileX === x && (tileY === y - 1 || tileY === y + 1)),
                Square.Orange,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileJ:
            if (
              validateSetTile(
                tileBlockRotate,
                x < 1 || x === maxWidth - 1 || y === maxHeight - 1,
                x === maxWidth - 1 || y === maxHeight - 1 || y === 0,
                x === maxWidth - 1 || y === 0 || x === 0,
                x === 0 || y === maxHeight - 1 || y === 0,
              )
            )
              break;

            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idBlockSave,
                (tileY === y && (tileX === x + 1 || tileX === x - 1)) ||
                  (tileY === y + 1 && tileX === x - 1),
                (tileX === x && (tileY === y - 1 || tileY === y + 1)) ||
                  (tileX === x + 1 && tileY === y + 1),
                (tileY === y && (tileX === x - 1 || tileX === x + 1)) ||
                  (tileY === y - 1 && tileX === x + 1),
                (tileX === x - 1 && tileY === y - 1) ||
                  (tileX === x && (tileY === y - 1 || tileY === y + 1)),
                Square.Blue,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileS:
            if (
              validateSetTile(
                tileBlockRotate,
                x < 1 || x === maxWidth - 1 || y === maxHeight - 1,
                x === maxWidth - 1 || y === maxHeight - 1 || y === 0,
                x === maxWidth - 1 || y === 0 || x === 0,
                x === 0 || y === maxHeight - 1 || y === 0,
              )
            )
              break;

            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idBlockSave,
                (tileY === y && tileX === x - 1) ||
                  (tileY === y + 1 && (tileX === x || tileX === x + 1)),
                (tileX === x && tileY === y + 1) ||
                  (tileX === x + 1 && (tileY === y - 1 || tileY === y)),
                (tileY === y && tileX === x + 1) ||
                  (tileY === y - 1 && (tileX === x - 1 || tileX === x)),
                (tileX === x - 1 && (tileY === y + 1 || tileY === y)) ||
                  (tileX === x && tileY === y - 1),
                Square.Green,
              ) ?? currentLayerState[keyTile];
            break;
          case TileBlock.TileZ:
            if (
              validateSetTile(
                tileBlockRotate,
                x < 1 || x === maxWidth - 1 || y === maxHeight - 1,
                x === maxWidth - 1 || y === maxHeight - 1 || y === 0,
                x === maxWidth - 1 || y === 0 || x === 0,
                x === 0 || y === maxHeight - 1 || y === 0,
              )
            )
              break;

            currentTile =
              mappingTileBlock(
                tileBlockRotate,
                x,
                y,
                keyTile,
                currentKey,
                idBlockSave,
                (tileY === y && tileX === x + 1) ||
                  (tileY === y + 1 && (tileX === x || tileX === x - 1)),
                (tileX === x && tileY === y - 1) ||
                  (tileX === x + 1 && (tileY === y + 1 || tileY === y)),
                (tileY === y && tileX === x - 1) ||
                  (tileY === y - 1 && (tileX === x + 1 || tileX === x)),
                (tileX === x - 1 && (tileY === y || tileY === y - 1)) ||
                  (tileX === x && tileY === y + 1),
                Square.Red,
              ) ?? currentLayerState[keyTile];
            break;
          default:
            if (tileMain.size === '2:2') {
              if (
                (tileY === y && tileX === x + 1) ||
                (tileY === y + 1 && (tileX === x || tileX === x + 1))
              ) {
                currentTile = {
                  tileId: DEFAULT_OVER_SIZE_TILE.id,
                  image: DEFAULT_OVER_SIZE_TILE.id,
                  rootX: x,
                  rootY: y,
                  isHidden: true,
                };
              } else if (keyTile === currentKey) {
                currentTile = {
                  tileId: tileMain.id,
                  image: tileMain.name,
                  rootX: x,
                  rootY: y,
                  isHidden: false,
                };
              }
            } else if (keyTile === currentKey) {
              currentTile = {
                tileId: tileMain.id,
                image: tileMain.name,
                rootX: x,
                rootY: y,
                isHidden: false,
              };
              if (tileMain.haveSegment) {
                const imageName = tileSegment?.imageName;
                if (imageName) currentTile = { ...currentTile, image: imageName };
                currentTile = {
                  ...currentTile,
                  durability: tileSegment?.durability,
                  spawnRate: tileSegment?.spawn,
                };
              }
              if (tileMain.haveMetaData) {
                const imageName = tileMeta?.imageName;
                // if (imageName)
                currentTile = { ...currentTile, image: imageName, index: tileMeta?.index };
              }
            }
            break;
        }

        all[keyTile] = currentTile;
        return all;
      }, {});

      return {
        ...current,
        [layers]: layerState,
      };
    });
  };

  const setLayer = (value: number) => {
    // init default value for layer
    setTiles((current) => {
      const curLayer = current[layers];
      Object.keys(curLayer).reduce<TilesState>((all, key) => {
        const [x, y] = key.split('_').map((item) => parseInt(item, 10));

        if (!curLayer[key]) {
          curLayer[key] = {
            tileId: DEFAULT_TILE.id,
            image: DEFAULT_TILE.name,
            rootX: x,
            rootY: y,
            isHidden: false,
          };
        }
        const currentTiles = curLayer[key];

        all[key] = currentTiles;
        return all;
      }, {});
      return current;
    });

    _setLayer(value);
  };

  /**
   * initial
   * @param isInit
   */
  const initForm = (isInit?: boolean) => {
    setTiles(getInitialTiles());

    if (isInit) {
      // eslint-disable-next-line no-console
      console.log('init');
      setStartLine(0);
      setLevelData(getInitialLevel());
      _setStages(3);
      _setStageSelected(1);
      _setLayer(3);
      setTileBlock('');
      setSelected('E');
      setTileGlobal(DEFAULT_TILE.id);
    }

    const mapLayerString = isInit
      ? getInitialLevel().boardMatrixs[stageSelected]
      : _.cloneDeep(levelData.boardMatrixs[stageSelected]);

    const arrObject = mapLayerString?.split(',');

    for (let i = 0; i < maxWidth; i += 1) {
      for (let j = 0; j < maxHeight; j += 1) {
        let objectData = DEFAULT_TILE;
        const arrObjectOnCell = arrObject[i * maxHeight + j]?.trim().split('-');
        for (let k = 0; k < arrObjectOnCell?.length; k += 1) {
          let durability = -1;
          let spawnRate = 0;

          // kiểu tra có phải zipline không?
          if (
            arrObjectOnCell[k].includes('.') &&
            Number(arrObjectOnCell[k].split('.')[0]) === 136
          ) {
            // const tileInfo = getDurAndSpawnInfo(arrObjectOnCell[k]);
            objectData = tileMap[arrObjectOnCell[k].split('.')[0]];
            const ziplineIndex = parseInt(arrObjectOnCell[k].split('.')[1], 10);
            setTileData(i, j, objectData, 4, durability, spawnRate, ziplineIndex);
            // eslint-disable-next-line no-continue
            continue;
          }
          // kiểm tra các object nằm trong tile có thuộc loại chứa durability hay không?
          else if (arrObjectOnCell[k].includes('|')) {
            // lấy durability and spawn cho tile nằm trên board
            const tileInfo = getDurAndSpawnInfo(arrObjectOnCell[k]);

            // if coin multi hits split durability and spawn rate
            objectData = tileMap[tileInfo.id];
            durability = tileInfo.durability;
            spawnRate = tileInfo.spawnRate;
          } else {
            objectData = tileMap[arrObjectOnCell[k]];
          }
          if (objectData?.id.includes('.')) {
            setTileBlock(objectData.id);
          }
          if (Number(objectData?.id) !== 0) {
            setTileData(i, j, objectData, k + 1, durability, spawnRate);
          }

          // if (Number(objectData?.id) === 136) {

          // }
        }
      }
    }
  };

  // useEffect(() => {
  //   let stageItems = [1];
  //   if (levelData.general.numberOfStages > 1) {
  //     stageItems = Array.from({ length: levelData.general.numberOfStages }, (__, i) => i + 1);
  //   }

  //   // count blocker initialization
  //   const widthItems = [...Array(maxWidth).keys()];
  //   const heightItems = [...Array(maxHeight).keys()];

  //   Bluebird.map(stageItems, (stageItem: number) => {
  //     const mapLayerString2 = levelData.boardMatrixs[stageItem];

  //     const arrObject2 = mapLayerString2?.split(',');
  //     // levelData.targetsAndRewards.blockerStatistic = {};

  //     Bluebird.map(widthItems, (i: number) => {
  //       Bluebird.map(heightItems, (j: number) => {
  //         let objectData = DEFAULT_TILE;
  //         const arrObjectOnCell = arrObject2[i * maxHeight + j]?.trim().split('-');
  //         for (let k = 0; k < arrObjectOnCell?.length; k += 1) {
  //           // kiểm tra các object nằm trong tile có thuộc loại chứa durability hay không?
  //           if (arrObjectOnCell[k].includes('|')) {
  //             // lấy durability and spawn cho tile nằm trên board
  //             const tileInfo = getDurAndSpawnInfo(arrObjectOnCell[k]);

  //             // if coin multi hits split durability and spawn rate
  //             objectData = tileMap[tileInfo.id];
  //           } else {
  //             objectData = tileMap[arrObjectOnCell[k]];
  //           }

  //           // thống kê số lương blockers
  //           if (objectData?.name) initBlockerStatistic(objectData.id, objectData.name);
  //         }
  //       });
  //     });
  //   });
  // }, [levelData]);

  const raiseBoardMaxtrixsInit = (height: number, hasRaise: boolean) => {
    const data: Array<string> = [];
    const boardSplit = levelData.boardMatrixs[1].split(',');
    const diffHeight = Math.abs(height - maxHeight);

    for (let index = 0; index < maxWidth; index += 1) {
      const heightSpice = (index + 1) * height;
      for (let index2 = diffHeight; index2 > 0; index2 -= 1) {
        if (hasRaise) boardSplit.splice(heightSpice - index2, 0, '0');
        else boardSplit.splice(heightSpice - index2, 1);
      }
    }

    for (let stageId = 1; stageId <= 1; stageId += 1) {
      let boardMatrixByStageId = '';
      for (let i = 0; i < maxWidth; i += 1) {
        for (let j = 0; j < height; j += 1) {
          const position = i * height + j;

          if (!boardMatrixByStageId) boardMatrixByStageId += boardSplit[position];
          else boardMatrixByStageId += `,${boardSplit[position]}`;
        }
      }
      data[stageId] = boardMatrixByStageId;
    }

    return data;
  };

  /**
   * add new row to grid
   * @param e
   */
  const addRow = (e: number, newBoard?: Record<number, string>) => {
    setMaxHeight(e);
    let hasRaise = false;
    if (maxHeight < e) {
      hasRaise = true;
    }
    const _newBoard = [...raiseBoardMaxtrixsInit(e, hasRaise)];
    _newBoard[0] = '';
    setLevelData((cur) => ({
      ...cur,
      boardMatrixs: newBoard ?? [..._newBoard],
    }));

    if (gridType !== GridType.Scrolling) setGridType(GridType.Scrolling);
  };

  const addStage = (e: number) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setStage(stages);
    let board: any = {};

    if (e > stages) {
      board = {
        ...levelData.boardMatrixs,
        ...getBoardMaxtrixsInit(e),
      };
    } else {
      Object.keys(levelData.boardMatrixs).forEach((x: any) => {
        const formatValue = Number(x);
        if (formatValue <= e) {
          board[formatValue] = levelData.boardMatrixs[formatValue];
        }
      });
    }

    _setStages(e);
    setLevelData((cur) => ({
      ...cur,
      boardMatrixs: board,
    }));
    _setStageSelected(e);
  };

  const changeGridType = (val: number) => {
    setGridType(val);
    setMaxHeight(DEFAULT_HEIGHT);
    _setStageSelected(1);
    _setStages(1);

    const board: string[] = [];

    Object.keys(levelData.boardMatrixs).forEach((x: any) => {
      const formatValue = Number(x);
      if (formatValue === 0) {
        board[formatValue] = '';
      }
      if (formatValue === 1) {
        board[formatValue] = levelData.boardMatrixs[formatValue];
      }
    });

    if (val === GridType.Scrolling) {
      setLevelData((cur) => ({
        ...cur,
        boardMatrixs: [...board],
      }));
    }
    formGeneral.setFieldsValue({ numberOfRows: DEFAULT_HEIGHT });
  };

  useEffect(() => {
    // formTargetAndRewards.resetFields();
    if (!levelData.boardMatrixs) return;
    if (
      typeof levelData !== 'object' ||
      levelData === undefined ||
      levelData === null ||
      !levelData?.boardMatrixs[stageSelected]
    ) {
      return;
    }

    // checkTileIsBlocker();
    // set tile block to set first tile show
    const _tileBlock = levelData.boardMatrixs[1]?.split(',').find((item) => item.includes('.'));
    if (_tileBlock) setTileBlock(_tileBlock);

    initForm();
  }, [levelData.boardMatrixs, stageSelected]);

  // useEffect(() => {
  // eslint-disable-next-line no-console
  // console.log('Resetting blockerStatistic');
  // setBlockerStatistic({});
  // checkTileIsBlocker();
  // }, [tiles]);

  useEffect(() => {
    initForm();
  }, [maxHeight, maxWidth]);

  useEffect(() => {
    if (!levelData.general) return;
    const { general } = levelData;

    general.embeddedId = general?.embeddedId ?? levelData.blocker?.embeddedId;

    // TODO : fix wrongs data temp. Can delete this when all data isn't string anymore
    if ((general.difficult as any) === 'Easy') general.difficult = 1;
    else if ((general.difficult as any) === 'Normal') general.difficult = 2;
    else if ((general.difficult as any) === 'Hard') general.difficult = 3;

    if (general.gridType === GridType.Scrolling) {
      setMaxHeight(general.numberOfRows);
      setGridType(GridType.Scrolling);
      setStartLine(general.startingLine);
      setScrollLinesAmount(general.scrollLinesAmount);
      // addRow(general.numberOfRows);
    } else {
      setMaxHeight(DEFAULT_HEIGHT);
      setGridType(GridType.Segment);
      _setStages(levelData.general.numberOfStages);
      if (levelData.general.numberOfStages < stageSelected) {
        _setStageSelected(1);
      }

      setChangedRow(14 - levelData.general.maxRow);
    }

    setMaxWidth(general.maxColumn > 0 ? general.maxColumn : DEFAULT_WIDTH);
    formGeneral.setFieldsValue(general);

    return () => {
      setStartLine(0);
    };
  }, [levelData.general]);

  useEffect(() => {
    const { chargeBarConfigs } = levelData;

    formChargeBar.resetFields();
    formChargeBar.setFieldsValue(chargeBarConfigs);
  }, [levelData.chargeBarConfigs]);

  useEffect(() => {
    if (!levelData?.targetsAndRewards?.clearObjects) return;
    const { targetsAndRewards } = levelData;
    formTargetAndRewards.resetFields(['clearObjects']);
    formTargetAndRewards.setFieldsValue({
      ...targetsAndRewards,
      clearObjects: targetsAndRewards.clearObjects,
    });
  }, [levelData.targetsAndRewards?.clearObjects]);

  useEffect(() => {
    const { blocker } = levelData;

    formBlockerSpawn.resetFields();
    formBlockerSpawn.setFieldsValue(blocker);
    if (blocker?.itemWithPositions && blocker?.itemWithPositions?.items) {
      Object.keys(blocker?.itemWithPositions?.items).forEach((key) => {
        switch (Number(key)) {
          case BlockType.TileI:
            setEmbeddedPositionI(blocker.itemWithPositions.items[BlockType.TileI]);
            break;
          case BlockType.TileT:
            setEmbeddedPositionT(blocker.itemWithPositions.items[BlockType.TileT]);
            break;
          case BlockType.TileO:
            setEmbeddedPositionO(blocker.itemWithPositions.items[BlockType.TileO]);
            break;
          case BlockType.TileL:
            setEmbeddedPositionL(blocker.itemWithPositions.items[BlockType.TileL]);
            break;
          case BlockType.TileS:
            setEmbeddedPositionS(blocker.itemWithPositions.items[BlockType.TileS]);
            break;
          case BlockType.TileZ:
            setEmbeddedPositionZ(blocker.itemWithPositions.items[BlockType.TileZ]);
            break;
          case BlockType.TileJ:
            setEmbeddedPositionJ(blocker.itemWithPositions.items[BlockType.TileJ]);
            break;

          default:
            break;
        }
      });
    } else {
      setEmbeddedPositionI([]);
      setEmbeddedPositionT([]);
      setEmbeddedPositionO([]);
      setEmbeddedPositionL([]);
      setEmbeddedPositionS([]);
      setEmbeddedPositionZ([]);
      setEmbeddedPositionJ([]);
    }
  }, [levelData.blocker]);

  // useEffect(() => {
  //   formChargeBar.resetFields();
  //   fetch();
  //   if (!chargebarTemplate[levelData.id]) return;

  //   formChargeBar.setFieldsValue({
  //     ...chargebarTemplate[levelData.id],
  //     $key: levelData.id,
  //   });
  // }, [levelData.id]);

  // useEffect(() => {
  //   const { targetsAndRewards } = levelData;

  //   formTargetAndRewards.resetFields(['blockerStatistic']);
  //   formTargetAndRewards.setFieldsValue({ blockerStatistic: targetsAndRewards?.blockerStatistic });
  // }, [levelData.targetsAndRewards?.blockerStatistic]);

  useEffect(() => {
    // const { tetromino } = levelData;

    formTetromino.resetFields();
  }, [levelData.tetromino]);

  /**
   * set sum permutation
   */
  const onChangeTetromino = (val: any, curKey: string) => {
    const tetromino = formTetromino.getFieldsValue();
    let permutation = 0;

    Object.keys(tetromino).forEach((property) => {
      if (property !== 'sequence' && property !== 'permutation' && property !== curKey)
        permutation += Number(tetromino[property]);
      else if (property === curKey) permutation += Number(val);
    });
    formTetromino.setFieldsValue({ permutation });
  };

  const copyStage = (_stage: number) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setStage(_stage);
    setStageCopy(levelData.boardMatrixs[_stage]);
  };

  const pasteStage = (_stage: number) => {
    const board = levelData.boardMatrixs;
    board[_stage] = stageCopy;

    setLevelData((cur) => ({
      ...cur,
      boardMatrixs: { ...board },
    }));
  };

  /**
   * event change stageSelected button
   * @param _stage
   */
  const setStage = (_stage: number) => {
    if (!levelData.boardMatrixs) return;
    const breakLayer = '-';
    const breakCell = ',';
    let boardMaxtrix = '';

    for (let col = 0; col < maxWidth; col += 1) {
      for (let row = 0; row < maxHeight; row += 1) {
        for (let layerData = 1; layerData <= maxLayer; layerData += 1) {
          const tileAtLayerData = { ...tiles[layerData] };
          const curTile = tileAtLayerData && tileAtLayerData[`${col}_${row}`];

          if (!curTile) {
            // eslint-disable-next-line no-continue
            continue;
          }
          let cellLayerTileId = `${curTile.tileId}`;

          if (
            tiles[1][`${col}_${row}`].tileId === '0' &&
            tiles[4][`${col}_${row}`].tileId !== '136'
          ) {
            boardMaxtrix += cellLayerTileId;
            if (layerData < maxLayer) {
              if (tileMap[tiles[2][`${col}_${row}`].tileId]?.layer2) {
                boardMaxtrix += breakLayer;
                const tileInfoInLayer = tiles[2][`${col}_${row}`];
                boardMaxtrix += Number(tileInfoInLayer.tileId);
                if (tileInfoInLayer.durability)
                  boardMaxtrix += `(${tileInfoInLayer.durability} | ${tileInfoInLayer.spawnRate})`;
              }
            }
            break;
          }

          // if (cellLayerTileId === '0') {
          //   // eslint-disable-next-line no-continue
          //   continue;
          // }

          if (curTile.index !== undefined) {
            cellLayerTileId = `${curTile.tileId}.${curTile.index}`;
          } else if (curTile?.durability && curTile?.durability !== -1) {
            const formatDurabiliti =
              curTile?.durability < 0 ? `_${curTile.durability * -1}` : `${curTile.durability}`;
            cellLayerTileId = `${cellLayerTileId}(${formatDurabiliti}|${curTile.spawnRate})`;
          }

          boardMaxtrix += cellLayerTileId;
          // }
          if (layerData < maxLayer) {
            // if (tiles[layerData + 1][`${col}_${row}`].tileId !== '0') {
            boardMaxtrix += breakLayer;
            // }
          }
        }

        if (row !== maxWidth || col !== maxHeight) {
          boardMaxtrix += breakCell;
        }
      }
    }
    if (boardMaxtrix.endsWith(',')) {
      boardMaxtrix = boardMaxtrix.slice(0, -1);
    }
    levelData.boardMatrixs[stageSelected] = boardMaxtrix;

    _setStageSelected(_stage);

    // checkTileIsBlocker(boardMaxtrix);

    return levelData.boardMatrixs;
  };

  useEffect(() => {
    // if (tiles === getInitialTiles()) return;
    const boardMaxtrix = setStage(stageSelected);

    checkTileIsBlocker(boardMaxtrix);
  }, [tiles]);

  const getDataMapLevelSave = (): MapLevelDataContext => {
    const data = levelData;
    const targetsAndRewardsData = formTargetAndRewards.getFieldsValue();
    if (
      (targetsAndRewardsData.type && targetsAndRewardsData.clearObjects) ||
      targetsAndRewardsData.type === TargetType.BonusLevel ||
      targetsAndRewardsData.type === TargetType.ClearLines
    ) {
      data.targetsAndRewards =
        (targetsAndRewardsData && targetsAndRewardsData) ?? levelData.targetsAndRewards;
    } else if (levelData.targetsAndRewards?.type) {
      data.targetsAndRewards = levelData.targetsAndRewards;
    } else {
      throw message.error('Cannot be blank Targets');
    }

    // setStage(stageSelected);

    // general form
    const generalFormData = formGeneral.getFieldsValue();
    const tetromino = formTetromino.getFieldsValue();

    const blockerData = formBlockerSpawn.getFieldsValue();

    // charge bar color setting
    const chargeBarsData = formChargeBar.getFieldsValue();

    data.id = generalFormData?.id?.toString() ?? levelData.id?.toString();
    data.general = {
      ...data.general,
      ...generalFormData,
    };

    data.chargeBarConfigs = chargeBarsData;

    const boardMatrixs: Array<string> = [];
    boardMatrixs[0] = '';

    if (generalFormData.numberOfStages) {
      Object.keys(data.boardMatrixs).forEach((item: string) => {
        if (Number(item) <= generalFormData.numberOfStages) {
          boardMatrixs[Number(item)] = data.boardMatrixs[Number(item)];
        }
        // delete data.boardMatrixs[Number(item)];
      });
      data.boardMatrixs = [];
      data.boardMatrixs = boardMatrixs;
    }
    // set init board default
    data.boardMatrixs[0] = '';
    // tetromino
    data.tetromino = Object.keys(tetromino).length > 0 ? tetromino : levelData.tetromino;

    data.blocker = Object.assign(BLOCKER_INIT, blockerData) as BlockerSpawn;

    const items: Record<number, number[]> = {};

    if (embeddedPositionI && embeddedPositionI.length > 0) {
      items[BlockType.TileI] = embeddedPositionI;
    }
    if (embeddedPositionT && embeddedPositionT.length > 0) {
      items[BlockType.TileT] = embeddedPositionT;
    }
    if (embeddedPositionO && embeddedPositionO.length > 0) {
      items[BlockType.TileO] = embeddedPositionO;
    }
    if (embeddedPositionL && embeddedPositionL.length > 0) {
      items[BlockType.TileL] = embeddedPositionL;
    }
    if (embeddedPositionJ && embeddedPositionJ.length > 0) {
      items[BlockType.TileJ] = embeddedPositionJ;
    }
    if (embeddedPositionS && embeddedPositionS.length > 0) {
      items[BlockType.TileS] = embeddedPositionS;
    }
    if (embeddedPositionZ && embeddedPositionZ.length > 0) {
      items[BlockType.TileZ] = embeddedPositionZ;
    }

    data.blocker.itemWithPositions.items = items;

    return data;
  };

  const clearLayer = () => {
    for (let i = 0; i < maxWidth; i += 1) {
      for (let j = 0; j < maxHeight; j += 1) {
        const objectData = DEFAULT_TILE;
        setTile(i, j, objectData);
      }
    }
  };

  const clearStage = () => {
    for (let k = 1; k <= maxLayer; k += 1) {
      for (let i = 0; i < maxWidth; i += 1) {
        for (let j = 0; j < maxHeight; j += 1) {
          const objectData = DEFAULT_TILE;
          setTile(i, j, objectData, k);
        }
      }
    }
  };

  /**
   * save
   * @returns MapLevelDataContext
   */

  useEffect(() => {
    const data: Record<string, ObjectCollection> = {};

    Object.keys(tileMap).forEach((element) => {
      imageExists(tileMap[element].url, (image: any) => {
        if (image) {
          data[tileMap[element].id] = tileMap[element];
          setTileMapFilter({ ...tileMapFilter, ...data });
        }
      });
    });
  }, [tileMap]);

  return {
    tileSegment,
    setTileSegment,
    tileMeta,
    setTileMeta,
    embeddedPositionI,
    setEmbeddedPositionI,
    embeddedPositionL,
    setEmbeddedPositionL,
    embeddedPositionJ,
    setEmbeddedPositionJ,
    embeddedPositionO,
    setEmbeddedPositionO,
    embeddedPositionS,
    setEmbeddedPositionS,
    embeddedPositionZ,
    setEmbeddedPositionZ,
    embeddedPositionT,
    setEmbeddedPositionT,
    startLine,
    setStartLine,
    setScrollLinesAmount,
    tileBlock,
    setTileBlock,
    clearLayer,
    clearStage,
    levelData,
    setLevelSelect,
    curLevel,
    setCurLevel,
    selectedTile,
    setSelectedTile,
    setLayer,
    selected,
    setSelected,
    layers,
    tileGlobal,
    setTileGlobal,
    stageSelected,
    stages,
    setStage,
    setTile,
    tiles,
    isMouseDown,
    setMouseDown,
    getDataMapLevelSave,
    _setStageSelected,
    stageCopy,
    copyStage,
    pasteStage,
    formGeneral,
    formObjective,
    formEnemy,
    formTetromino,
    formBlockerSpawn,
    formTargetAndRewards,
    // formAiScore,
    _setLayerSelected,
    layerSelected,
    onChangeTetromino,
    curColumn,
    setCurColumn,
    getInitialLevel,
    initForm,
    maxHeight,
    setMaxHeight,
    maxWidth,
    setMaxWidth,
    gridType,
    changeGridType,
    addRow,
    changedRow,
    setChangedRow,
    addStage,
    tileMapFilter,
    maxLayer,
    tetrominoInit,
    formChargeBar,
    syncBlockerTile,
    blockerStatistic,
    setLevelData,
  };
};

export type LevelDataConfigContext = ReturnType<typeof useLevelDataContextValue>;
const levelDataConfigContext = createContext<LevelDataConfigContext | null>(null);

export const LevelDataConfigContextProvider: FC = ({ children }) => {
  const value = useLevelDataContextValue();

  return (
    <levelDataConfigContext.Provider value={value}>{children}</levelDataConfigContext.Provider>
  );
};

export const useLevelDataConfigContext = () => {
  const ctx = useContext(levelDataConfigContext);
  if (!ctx) {
    throw new Error('useLevelDataConfigContext must be used inside LevelDataConfigContextProvider');
  }

  return ctx;
};
