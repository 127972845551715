// difficult type of level parameter
const DIFFICULT_TYPE = [
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: 'Hard',
    value: 2,
  },
  {
    label: 'Very Hard',
    value: 3,
  },
];
// choose a grid data type
const SEGMENT_TRANSITION_TYPE = ['Vertical', 'Horizontal'];
const TARGET_AND_REWARDS = ['ClearLines', 'Score'];
const TETROMINO_IDS = [52, 54, 55, 56, 57, 58, 53];
const TILEBLOCK = {
  TileI: 52,
  TileT: 53,
  TileO: 54,
  TileL: 55,
  TileJ: 56,
  TileS: 57,
  TileZ: 58,
};

export { DIFFICULT_TYPE, TARGET_AND_REWARDS, SEGMENT_TRANSITION_TYPE, TETROMINO_IDS, TILEBLOCK };
