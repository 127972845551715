import React, { FC, useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { TARGET_AND_REWARDS } from '@pages/map-level/utils/constants';
import { TargetType } from '@pages/map-level/utils/enumeration';

import Blockers from './component/blockers';
import ClearObjects from './component/clear-objects';
import Rewards from './component/rewards';

const { Item } = Form;

export type TargetAndRewardsProps = {
  form: FormInstance;
  viewOnly: boolean;
};

const TargetAndRewards: FC<TargetAndRewardsProps> = ({ form, viewOnly }) => {
  const [targetType, setTargetType] = useState<TargetType>(TargetType.None);
  const { levelData, syncBlockerTile, setLevelData } = useLevelDataConfigContext();

  const _setTargetType = (value: number) => {
    // setTargetType(value);
    setLevelData({
      ...levelData,
      targetsAndRewards: {
        ...levelData.targetsAndRewards,
        type: value ?? TargetType.None,
      },
    });
  };

  useEffect(() => {
    setTargetType(levelData?.targetsAndRewards?.type ?? TargetType.None);
  }, [levelData?.targetsAndRewards?.type]);

  useEffect(() => {
    const clearObjectsValue = form.getFieldValue('clearObjects');
    if (clearObjectsValue) {
      setLevelData({
        ...levelData,
        targetsAndRewards: {
          ...levelData.targetsAndRewards,
          clearObjects: clearObjectsValue,
        },
      });
    }
  }, [form]);

  return (
    <Form
      form={form}
      // labelCol={{ span: 24 }}
      // initialValues={levelData.targetsAndRewards ?? TARGET_AND_REWARDS_INIT}
      // wrapperCol={{ span: 24 }}
      layout="horizontal"
      name="targetAndRewards"
    >
      <Row>
        <Col span={8}>
          <div className="mb-3 font-bold">Targets</div>
          <Item label="Type" name="type">
            {TARGET_AND_REWARDS && (
              <Select
                disabled={viewOnly}
                placeholder="Please choose a targets"
                style={{ width: 150, textAlign: 'center' }}
                onChange={(value: any) => _setTargetType(value)}
                options={TARGET_AND_REWARDS.map((item, index) => {
                  const val = index + 1;

                  if (val === 2) {
                    return {
                      value: -2,
                      label: item,
                    };
                  }
                  return {
                    value: val,
                    label: item,
                  };
                })}
              />
            )}
          </Item>

          {targetType === TargetType.ClearLines && (
            <Item label="Number of line" name="numberOfLine" className="mt-3">
              <InputNumber disabled={viewOnly} style={{ width: 150 }} defaultValue={40} min={0} />
            </Item>
          )}
          {targetType === TargetType.Score && (
            <Item label="Score" name="score" className="mt-3">
              <InputNumber disabled={viewOnly} style={{ width: 150 }} min={0} />
            </Item>
          )}
          {/* {targetType === TargetType.BonusLevel && <BonusObject />} */}
        </Col>
        {/* <Col span={8}>
          <div className="mb-3 font-bold ">Rewards</div>
          <Rewards viewOnly={viewOnly} />
        </Col> */}
        {/* <Col span={8}>
          <div className="mb-3 font-bold">Blocker Statistic</div>
          
          <Form.List name={['blockerStatistic']}>
            {(fields) => (
              <>
                {fields.map((field) => (
                  <Blockers field={field} syncBlockerTile={syncBlockerTile} />
                ))}
              </>
            )}
          </Form.List>
        </Col> */}
      </Row>
    </Form>
  );
};

export default TargetAndRewards;
